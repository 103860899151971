import React from "react";
import TrainerManage from "../components/management/trainerManagement/trainerManage";
import RecruiterLayout from "../components/admin/layout/Layout";
import TrainerAvailable from "../components/management/trainerManagement/trainerAvailable";

const TranerManage = () => {
  return (
    <>
     
        <TrainerManage />
       
    </>
  );
};

export default TranerManage;
