import React from 'react'
import TrainerData from '../components/trainer/trainerData'

const TrainerJoin = () => {
  return (
    <>
      <TrainerData/>
    </>
  )
}

export default TrainerJoin
